<div class="toolbar" role="banner">
  <p>
    <button
      class="menu-button-icon"
      [hidden]="!userAuthenticated"
      mat-button
      (click)="sidenav.toggle()"
    >
      <mat-icon class="color_menu">menu</mat-icon>
    </button>
  </p>

  <span [hidden]="!userAuthenticated"
    >{{ "greeting" | translate }} {{ _user }}</span
  >

  <button class="auth-buttons" [hidden]="userAuthenticated" (click)="login()">
    {{ "login" | translate }}
  </button>
  <button class="auth-buttons" [hidden]="!userAuthenticated" (click)="logout()">
    {{ "logout" | translate }}
  </button>
  <div class="spacer"></div>

  <div class="switchLang">
    <label for="language-picker-select">{{
      "selectlanguage" | translate
    }}</label>
    <select
      [(ngModel)]="languageSwitched"
      (change)="switchLanguage($event)"
      name="language-picker-select"
      id="language-picker-select"
    >
      <option value="en">{{ "english" | translate }}</option>
      <option value="ar">{{ "arabic" | translate }}</option>
    </select>
  </div>
</div>

<mat-sidenav-container class="full-screen">
  <mat-sidenav
    class="side_nav_container"
    mode="side"
    #sidenav
    mode="side"
    [(opened)]="opened"
    (opened)="events.push('open!')"
    (closed)="events.push('close!')"
  >
    <mat-list *ngIf="hasPermission && !isRestaurantAdmin">
      <a [routerLink]="''" mat-list-item class="mat-list-item">
        <mat-icon class="_8orders-color">dashboard</mat-icon>
        <span>{{ "dashBoard" | translate }}</span>
      </a>

      <a (click)="open()" mat-list-item class="cursor">
        <mat-icon class="_8orders-color">restaurant_menu</mat-icon>
        <span>{{ "menu" | translate }}</span>
      </a>

      <a routerLink="exel/updatePricesByExcel" mat-list-item>
        <mat-icon>file_copy</mat-icon>
        <span>{{ "updatePrices" | translate }}</span>
      </a>

      <a routerLink="exel/addItemByExcel" mat-list-item>
        <mat-icon>file_copy</mat-icon>
        <span>{{ "importCategory" | translate }}</span>
      </a>
    </mat-list>

    <mat-list>
      <div *ngIf="isRestaurantAdmin || isRestaurant">
        <a [routerLink]="''" mat-list-item class="mat-list-item">
          <mat-icon class="_8orders-color">dashboard</mat-icon>
          <span>{{ "dashBoard" | translate }}</span>
        </a>

        <a
          routerLink="restaurant/restaurantBusyHistory"
          mat-list-item
          class="mat-list-item"
          *ngIf="isRestaurantAdmin"
        >
          <mat-icon class="_8orders-color">timer</mat-icon>
          <span>{{ "restaurantBusy" | translate }}</span>
        </a>
        <a routerLink="cat/menu-category" mat-list-item>
          <mat-icon class="_8orders-color">restaurant_menu</mat-icon>
          <span>{{ "menu" | translate }}</span>
        </a>
        <a routerLink="restaurant/restaurantReviews" mat-list-item>
          <mat-icon class="_8orders-color">reviews</mat-icon>
          <span> {{ "reviews" | translate }}</span>
        </a>

        <a
          *ngIf="isRestaurantAdmin"
          routerLink="exel/updatePricesByExcel"
          mat-list-item
        >
          <mat-icon>file_copy</mat-icon>
          <span>{{ "updatePrices" | translate }}</span>
        </a>

        <a
          *ngIf="isRestaurantAdmin"
          routerLink="exel/addItemByExcel"
          mat-list-item
        >
          <mat-icon>file_copy</mat-icon>
          <span>{{ "importCategory" | translate }}</span>
        </a>

        <mat-accordion>
          <mat-expansion-panel style="background-color: #def0ff">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="_8orders-color">description</mat-icon>
                {{ "reports" | translate }}</mat-panel-title
              >
            </mat-expansion-panel-header>
            <div *ngFor="let report of reportsList" class="pointer">
              <a (click)="OpenReport(report.name)" mat-list-item>
                <mat-icon class="_8orders-color">description</mat-icon>
                <span>{{ report.name | translate }}</span>
              </a>
            </div>         
            <div class="pointer">
              <a routerLink="report/restaurants/ItemStatusReport" mat-list-item>
                <mat-icon class="_8orders-color">description</mat-icon>
                <span>{{ "itemsStatesReport" | translate }}</span>
              </a>
            </div>
            <a mat-list-item  *ngIf="isRestaurantAdmin" routerLink="/reports/merchantPerformanceReport">
              <mat-icon class="_8orders-color">description</mat-icon>
              <span>{{ "MerchantPerformanceReport" | translate }}</span>
            </a>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <a
        routerLink="/orders/restaurantOrders"
        mat-list-item
        class="mat-list-item"
        *ngIf="isRestaurantAdmin || isRestaurant"
      >
        <mat-icon class="_8orders-color">restaurant</mat-icon>
        <span>{{ "orders" | translate }}</span>
      </a>

      <a
        routerLink="orders/restaurantOrdersHistory"
        mat-list-item
        class="mat-list-item"
        *ngIf="isRestaurantAdmin || isRestaurant"
      >
        <mat-icon class="_8orders-color">restaurant</mat-icon>
        <span>{{ "ordersHistory" | translate }} </span>
      </a>

      <a
        *ngIf="isRestaurantAdmin"
        routerLink="users"
        mat-list-item
        class="mat-list-item"
      >
        <mat-icon class="_8orders-color">group</mat-icon>
        <span>{{ "users" | translate }}</span>
      </a>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content class="padding_15px">
    <router-outlet> </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
