import { Component, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { _8OrdersToastrService } from './Shared/services/_8OrdersToastrService';
import { AuthService } from './Shared/services/auth.service';
import { from, Observable, of } from 'rxjs';
import { User } from 'oidc-client';
import {
  ReportListClient,
  ReportsListDTO,
  UserPermissionsClient,
} from './Shared/services/8OrdersRestaurantsclient';
import { Router } from '@angular/router';
import { RestaurantSignalRServices } from './Shared/services/RestaurantSignalRServices';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  reportsList: Reports[] = new Array();
  title = 'AppRoot';
  opened = false;
  role: string;
  subUserId: string;
  userId: number;
  restaurantId: string;
  restIdFromAdmin: number = 0;
  isRestaurantAdmin: boolean = false;
  isRestaurant: boolean = false;
  isAdmin: boolean = false;
  hasPermission: boolean = false;
  //reports: ReportsListDTO[] = [];
  events: string[] = [];
  userPermissionList: string[] = [];
  languageSwitched: string = '';

  hasPermission$: Observable<boolean>;
  public userAuthenticated = false;
  public _user: string | undefined;
  public username$: Observable<User | null>;
  constructor(
    private _authService: AuthService,
    private reportList: ReportListClient,
    private permissionClient: UserPermissionsClient,
    private router: Router,
    private restaurantSignalRServices: RestaurantSignalRServices,
    private translate: TranslateService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    var restaurantId = localStorage.getItem('restaurantIdFromAdmin') as string;
    this.restIdFromAdmin = parseInt(restaurantId);
    
    this._authService.isAuthenticated().then(isAuthenticated => 
      {
        this.userAuthenticated = isAuthenticated;
        
        if(isAuthenticated)
          this.GetUserPermission();
      });

    this._authService.loginChanged$.subscribe((isAuthenticated) => {
      if(isAuthenticated)
        this.LoginChange();
    });

    translate.setDefaultLang(this.languageSwitched);
    const savedLanguage = localStorage.getItem('selectedLanguage');

    // Set the default language or the saved language
    this.languageSwitched = savedLanguage || 'en';
    this.translate.setDefaultLang('en');
    this.translate.use(this.languageSwitched);

    this.reportList.list().subscribe((data) => {
      data.forEach((report) => {
        var reportsList = new Reports(
          report.name as string,
          report.path as string
        );

        this.reportsList.push(reportsList);
      });
    });
  }

  ngOnInit(): void {
    this.createSessionId();
    const savedLanguage = localStorage.getItem('selectedLanguage');

    if (savedLanguage === 'ar') {
      const selectLang =
        this.elementRef.nativeElement.querySelector('.switchLang');
      if (selectLang) {
        this.renderer.setAttribute(selectLang, 'dir', 'rtl');
      }
    }
  }

  OpenReport(e: any) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/report', e]));
  }

  public LoginChange() {
    this._authService.GetUser().then((x) => {
      localStorage.setItem('UserName', x?.profile['name'] as string);
      this._user = x?.profile['name'];
      this.role = x?.profile['role'];
      this.subUserId = x?.profile['sub'] as string;
      this.userId = parseInt(this.subUserId);

      this.restaurantId = x?.profile['RestaurantId'];
      this.role == 'RestaurantAdmin'
        ? (this.isRestaurantAdmin = true)
        : (this.isRestaurantAdmin = false);

      this.role == 'restaurant'
        ? (this.isRestaurant = true)
        : (this.isRestaurant = false);

      this.role?.toLocaleLowerCase() ?? '' === 'admin'.toLocaleLowerCase()
        ? (this.isAdmin = true)
        : (this.isAdmin = false);
    });

    this.GetUserPermission();
  }

  public login = () => {
    this._authService.login();
  };
  public logout = () => {
    this._authService.logout();
  };

  GetUserPermission() {
    this._authService.GetUser().then((x) => {
      this.subUserId = x?.profile['sub'] as string;
      this.userId = parseInt(this.subUserId);

      this.permissionClient
        .getUserPermissions(this.userId)
        .subscribe((data) => {
          this.userPermissionList = data;
          const filteredList = this.userPermissionList.filter(
            (permission) => permission === 'Restaurants'
          );
          if (filteredList.length > 0) {
            this.hasPermission = true;
          }
        });
    });
  }

  switchLanguage(event: any): void {
    const selectedLanguage = (event.target as HTMLSelectElement).value;
    this.translate.use(selectedLanguage);
    this.languageSwitched = selectedLanguage;
    localStorage.setItem('selectedLanguage', selectedLanguage);
    location.reload();
  }

  open() {
    var restaurantId = localStorage.getItem('restaurantIdFromAdmin') as string;
    let id = parseInt(restaurantId);
    this.router.navigate(['cat/menu/' + id]);
  }

  createSessionId() {
    if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', this.generateUUID());
    }
  }

  generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
  }
}

export class Reports {
  name: string;
  path: string;

  constructor(name: string, path: string) {
    this.name = name;
    this.path = path;
  }
}
