import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // Preload only if the route has `data.preload` set to true
    if (route.data && route.data['preload']) {
      return load();
    }
    return of(null); // Do not preload other modules
  }
}
