import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ToastrModule } from 'ngx-toastr';
import { _8OrdersToastrService } from './Shared/services/_8OrdersToastrService';
import { PreloadAllModules, RouterModule } from '@angular/router';
import appRoutes from './appRoutes';
import { SigninRedirectCallbackComponent } from './signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './signout-redirect-callback.component';
import { MainContentComponent } from './Components/maincontent.component/maincontent.component';
import { OAuthInterceptor } from './Shared/services/OAuthInterceptor';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import {
  API_BASE_URL,
  ReportListClient,
  UserPermissionsClient,
} from './Shared/services/8OrdersRestaurantsclient';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RestaurantSignalRServices } from './Shared/services/RestaurantSignalRServices';

import { MatExpansionModule } from '@angular/material/expansion';
import { AppConfigService } from './Shared/services/AppConfigService';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomPreloadingStrategy } from './CustomPreloadingStrategy ';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    MainContentComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(appRoutes, { preloadingStrategy: CustomPreloadingStrategy }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
    _8OrdersToastrService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useFactory: getUrlFromConfiguration,
      deps: [AppConfigService],
    },
    ReportListClient,
    RestaurantSignalRServices,
    UserPermissionsClient,
    CustomPreloadingStrategy
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}

export function getUrlFromConfiguration(appConfig: AppConfigService): string {
  return appConfig?.Config?.restaurantBaseUrl;
}

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig().then();
}
