<div class="content" role="main">
  <h2>Merchant Ops</h2>

  <div
    class="card-container"
    [hidden]="!userAuthenticated"
    *ngIf="hasPermission && !isRestaurantAdmin"
  >
    <a class="card" rel="noopener" routerLink="cat/menu/{{ restaurantId }}">
      <mat-icon class="_8orders-color">restaurant_menu</mat-icon>
      <span>{{ "menu" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a routerLink="exel/addItemByExcel" class="card" rel="noopener">
      <mat-icon class="_8orders-color">file_copy</mat-icon>
      <span>{{ "importCategory" | translate }} </span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a routerLink="exel/updatePricesByExcel" class="card" rel="noopener">
      <mat-icon class="_8orders-color">file_copy</mat-icon>
      <span>{{ "updatePrices" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>
  </div>

  <div class="card-container" [hidden]="!userAuthenticated">
    <a
      routerLink="/orders/newOrders"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin || isRestaurant"
    >
      <mat-icon class="_8orders-color">restaurant</mat-icon>
      <span>{{ "newOrders" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>
    <a
      routerLink="/orders/holdingOrders"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin || isRestaurant"
    >
      <mat-icon class="_8orders-color">restaurant</mat-icon>
      <span>{{ "holdingOrders" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>
    <a
      routerLink="/orders/cookingOrders"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin || isRestaurant"
    >
      <mat-icon class="_8orders-color">restaurant</mat-icon>
      <span>{{ "cookingOrders" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      routerLink="orders/restaurantOrdersHistory"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin || isRestaurant"
    >
      <mat-icon class="_8orders-color">restaurant</mat-icon>
      <span>{{ "ordersHistory" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      routerLink="restaurant/restaurantBusyHistory"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin"
    >
      <mat-icon class="_8orders-color">timer</mat-icon>
      <span>{{ "restaurantBusy" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      class="card"
      rel="noopener"
      routerLink="cat/menu-category"
      *ngIf="isRestaurantAdmin || isRestaurant"
    >
      <mat-icon class="_8orders-color">restaurant_menu</mat-icon>
      <span>{{ "menu" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      routerLink="restaurant/restaurantReviews"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin || isRestaurant"
    >
      <mat-icon class="_8orders-color">reviews</mat-icon>
      <span>{{ "reviews" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      routerLink="exel/updatePricesByExcel"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin"
    >
      <mat-icon class="_8orders-color">file_copy</mat-icon>
      <span>{{ "updatePrices" | translate }}</span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>

    <a
      routerLink="exel/addItemByExcel"
      class="card"
      rel="noopener"
      *ngIf="isRestaurantAdmin"
    >
      <mat-icon class="_8orders-color">file_copy</mat-icon>
      <span>{{ "importCategory" | translate }} </span>
      <svg
        class="material-icons"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </a>
  </div>
</div>
