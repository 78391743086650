import { Injectable } from "@angular/core";
import * as signalR from '@microsoft/signalr';
import { AppConfigService } from "./AppConfigService";
import { AuthService } from "./auth.service";
import { Subject } from "rxjs";
import { Collaborator } from "../Types/Collaborator";


@Injectable()
export class RestaurantSignalRServices{
  connection:signalR.HubConnection;
  private liveCollabHubConnection: signalR.HubConnection;

  private _liveCollabConnectionEstablished$ = new Subject<void>();
  private _orderCollaboratorsUpdated$ = new Subject<Collaborator[]>();
  private _liveCollabConnectionClosed$ = new Subject<void>();
  private _orderUpdatedByAdmin$ = new Subject<void>();

  liveCollabConnectionEstablished$ = this._liveCollabConnectionEstablished$.asObservable();
  orderCollaboratorsUpdated$ = this._orderCollaboratorsUpdated$.asObservable();
  liveCollabConnectionClosed$ = this._liveCollabConnectionClosed$.asObservable();
  orderUpdatedByAdmin$ = this._orderUpdatedByAdmin$.asObservable();
  
  constructor(public config: AppConfigService,
    private authService :  AuthService){
      this.authService.loginChanged$.subscribe(isAuthenticated => {

        if(isAuthenticated && !this.connection){
          this.connectToRestaurantHub();
          this.connectToLiveCollabHub();  
        }
      });
  }

  connectToRestaurantHub(){
    this.connection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(`${this.config.getConfig()?.restaurantBaseUrl}/operationHub`, { accessTokenFactory: () => this.authService.getAccessToken() })
          .withAutomaticReconnect()
          .build();
          
          
    this.connection
      .start()
      .then(function () {
        console.log('SignalR Connected!');
      }).catch(function (err) {
        console.error(err.toString());
      });  
  }

  
  connectToLiveCollabHub(){
    this.liveCollabHubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(`${this.config.Config.liveCollabServiceUrl}/CollabHub`, {
          accessTokenFactory: () => this.authService.getAccessToken()
        })
        .build();

        this.handleSignalRDisconnection(this.liveCollabHubConnection);

      this.liveCollabHubConnection
      .start()
      .then(() => 
        {
          this.BindLiveCollabEvents()
          this._liveCollabConnectionEstablished$.next();
        })
        .catch(function (err) {
          console.error(err.toString());
        });
        
  }

public GethubConnection():signalR.HubConnection{

  return this.connection;
}

public orderViewingStarted(orderId: number){
  if(this.liveCollabHubConnection.state == signalR.HubConnectionState.Connected){
    this.liveCollabHubConnection.invoke("OrderViewingStarted", orderId, sessionStorage.getItem('sessionId'))
      .catch(err => console.error("Error invoking hub method:", err));
  }
}

public orderViewingEnded(orderId: number){
  if(this.liveCollabHubConnection.state == signalR.HubConnectionState.Connected){
    this.liveCollabHubConnection.invoke("OrderViewingEnded", orderId, sessionStorage.getItem('sessionId'))
      .catch(err => console.error("Error invoking hub method:", err));
  }
}

public OrderUpdatedByRestaurant(orderId: number){
  if(this.liveCollabHubConnection.state == signalR.HubConnectionState.Connected){
    this.liveCollabHubConnection.invoke("OrderUpdatedByRestaurant", orderId)
      .catch(err => console.error("Error invoking hub method:", err));
  }
}

private BindLiveCollabEvents(){
  this.liveCollabHubConnection.on('OrderCollaboratorsUpdated', (collaborators: Collaborator[]) => this._orderCollaboratorsUpdated$.next(collaborators));
  this.liveCollabHubConnection.on('OrderUpdatedByAdmin', (collaborators: Collaborator[]) => this._orderUpdatedByAdmin$.next());
}

async handleSignalRDisconnection(connection: signalR.HubConnection) {
  let retryCount = 0;
  const maxRetries = 3;
  const baseRetryDelay = 3000;
  const that = this;
  async function reconnect() {
      while (retryCount < maxRetries) {
          try {
              console.log(`Attempting to reconnect... (Attempt ${retryCount + 1})`);
              await connection.start();
              console.log("Reconnected successfully!");
              that.BindLiveCollabEvents()
              that._liveCollabConnectionEstablished$.next();
              return; // Exit the function if reconnection succeeds
          } catch (err) {
              retryCount++;
              const retryDelay = baseRetryDelay * Math.pow(2, retryCount - 1); // Exponential backoff
              console.log(`Reconnect attempt failed. Retrying in ${retryDelay / 1000} seconds...`);
              await that.delay(retryDelay); // Wait before retrying
          }
      }

      // If it reaches here, all retries have failed
      that._liveCollabConnectionClosed$.next();
  }

  connection.onclose(async () => {
      console.log("Connection lost. Trying to reconnect...");
      await reconnect();
  });
}

// Helper function to wait for a specified amount of time
delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


}






